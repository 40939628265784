// @ts-nocheck
import React from "react";
import {
  Typography,
  Grid,
  withStyles,
  createStyles,
  Button,
  TextField,
  FormControl,
} from "@material-ui/core";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";

import Slider from "@material-ui/core/Slider";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import { surveyStyles, aboutUsStyle } from "./surveyStyles";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const AccountingSchema = Yup.object().shape({
  isHelpNeededinAccounting: Yup.string().required("This field is required"),
  noOFBankAccount: Yup.string(),
  isManageInvoice: Yup.string().required("This field is required"),
  noOFInvoice: Yup.string(),
  isBalanceSheetAccurate: Yup.string(),
  noOfmonthForBalanceSheet: Yup.string(),
});

const PrettoSlider = withStyles((theme) =>
  createStyles({
    root: {
      width: "75%",
      height: 8,
      padding: 0,
      color: theme.palette.primary.main,
    },
    thumb: {
      width: 0,
      height: 0,
      border: "none",
      marginLeft: -24,
      marginTop: -8,
      backgroundColor: "#fff",
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },
    active: {},
    valueLabel: {
      top: 22,
      left: "calc(-50% + 4px)",
      "& *": {
        fontWeight: 500,
        color: theme.palette.text.primary,
        background: "transparent",
        fontSize: "1rem",
      },
    },
    track: {
      borderRadius: 10,
      height: 10,
    },
    rail: {
      background: "rgba(75, 66, 68, 0.4)",
      borderRadius: 10,
      height: 10,
    },
    mark: {
      height: 8,
      width: 1,
      marginTop: -3,
      backgroundColor: "#bfbfbf",
    },
    markActive: {
      backgroundColor: "red",
      opacity: 1,
    },
  })
)(Slider);

function valuetext(value: number) {
  return `${value}`;
}
const ALERADY_EXISTING_CLIENT = "I am an existing client of {Practice}";

export const AccountingComponent = (props) => {
  const { classes, gotoBackStep, gotoNextStep, setValueByDynamicName } = props;
  const step1 = props.step1Selection.attributes.description;
  const getTitle = () => {
    const { currentAccountingSystem } = props;
    if (currentAccountingSystem === "None") {
      return "Do you need any assistance with setting up accounting for your business?";
    } else if (currentAccountingSystem !== "None") {
      if (step1 === ALERADY_EXISTING_CLIENT) {
        return "Provide any update about your accounting.";
      } else {
        return "Tell us about your accounting";
      }
    }
  };
  const getAccontingTitle = () => {
    const { currentAccountingSystem } = props;
    if (currentAccountingSystem === "None") {
      return "";
    } else {
      return "Do you need any help with managing your ongoing accounting?";
    }
  };
  const getNoButtonStyle = (values, key) => {
    let test = {
      color: "#ccc",
      background: "#fff",
    };
    if (values[key] === "false") {
      test = {
        background: "#F96064",
        color: "#fff",
      };
    }
    return test;
  };
  const getNoButtonStyleNew = (values, key) => {
    let test = {
      color: "#ccc",
      background: "#fff",
    };
    if (values[key] === false) {
      test = {
        background: "#F96064",
        color: "#fff",
      };
    }
    return test;
  };

  const getError = (errors, touched, key) => {
    return touched[key] && errors[key] ? errors[key] : "";
  };

  return (
    <Fade in={true} timeout={3000}>
      <Formik
        initialValues={{
          isHelpNeededinAccounting: props.isHelpNeededinAccounting,
          noOFBankAccount: props.noOFBankAccount,
          isManageInvoice: props.isManageInvoice,
          noOFInvoice: props.noOFInvoice,
          isBalanceSheetAccurate: props.isBalanceSheetAccurate,
          noOfmonthForBalanceSheet: props.noOfmonthForBalanceSheet,
        }}
        validationSchema={AccountingSchema}
        // enableReinitialize
        onSubmit={(values) => {
          gotoNextStep();
        }}
      >
        {(formikProps) => {
          const {
            values,
            handleBlur,
            handleChange,
            setFieldValue,
            isValid,
            errors,
            touched,
          } = formikProps;
          const goBack = (m) => {
            setFieldValue("isHelpNeededinAccounting", "");
            setFieldValue("noOFBankAccount", "");
            setFieldValue("isManageInvoice", "");
            setFieldValue("noOFInvoice", "");
            setFieldValue("isBalanceSheetAccurate", "");
            setFieldValue("noOfmonthForBalanceSheet", "1");

            props.setValueByDynamicName(
              "isHelpNeededinAccounting",
              ""
            );
            props.setValueByDynamicName("noOFBankAccount", "");
            props.setValueByDynamicName("isManageInvoice", "");
            props.setValueByDynamicName("noOFInvoice", "");
            props.setValueByDynamicName(
              "isBalanceSheetAccurate",
              ""
            );
            props.setValueByDynamicName(
              "noOfmonthForBalanceSheet",
              "1"
            );
            gotoBackStep();
          }
          const handleButtonChangeYes = (event) => {
            setValueByDynamicName(event.currentTarget.name, "true");
            setFieldValue(event.currentTarget.name, "true");
          };
          const handleButtonChangeNo = (event) => {
            setValueByDynamicName(event.currentTarget.name, "false");
            setFieldValue(event.currentTarget.name, "false");
          };
          const handleBalanceSheetChangeYes = () => {
            setValueByDynamicName("isBalanceSheetAccurate", true);
            setFieldValue("isBalanceSheetAccurate", true);
            setValueByDynamicName("noOfmonthForBalanceSheet", 0);
            setFieldValue("noOfmonthForBalanceSheet", 0);
          };
          const handleBalanceSheetChangeNo = () => {
            setValueByDynamicName("isBalanceSheetAccurate", false);
            setFieldValue("isBalanceSheetAccurate", false);
          };
          const handless = (event) => {
            setValueByDynamicName(
              "noOfmonthForBalanceSheet",
              event.target.value
            );
            handleChange(event);
          };
          return (
            <Form autoComplete="off" noValidate>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="flex-start"
                className={classes.maingrid3}
              >
                <Grid item xs={12}>
                  <Typography className={classes.pageHeading} gutterBottom>
                    {getTitle()}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.question} gutterBottom>
                    {getAccontingTitle()}
                  </Typography>
                </Grid>

                <Grid item xs={11} sm={8}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={11} sm={4}>
                      <Button
                        fullWidth
                        classes={{ root: classes.buttonRoot }}
                        id="isHelpNeededinAccounting-yes"
                        name="isHelpNeededinAccounting"
                        variant="outlined"
                        color="default"
                        style={
                          props.isHelpNeededinAccounting === "true"
                            ? {
                                background:
                                  localStorage.getItem("themePrimaryColor") ||
                                  "#5BA8AA",
                                color: "#fff",
                              }
                            : {
                                color: "#ccc",
                                background: "#fff",
                              }
                        }
                        onClick={handleButtonChangeYes}
                      >
                        Yes
                      </Button>
                    </Grid>
                    <Grid item xs={11} sm={4}>
                      <Button
                        fullWidth
                        classes={{ root: classes.buttonRoot }}
                        variant="outlined"
                        color="default"
                        style={getNoButtonStyle(
                          values,
                          "isHelpNeededinAccounting"
                        )}
                        id="isHelpNeededinAccounting-no"
                        name="isHelpNeededinAccounting"
                        onClick={handleButtonChangeNo}
                      >
                        No
                      </Button>
                    </Grid>
                    <Grid xs={12} item>
                      <Typography color="error">
                        {getError(errors, touched, "isHelpNeededinAccounting")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.question} gutterBottom>
                    How many bank and/or credit card account do you have for
                    your business?
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={8}>
                  <div style={{ position: "relative", width: "100%" }}>
                    {props.noOFBankAccount != 0 && (
                      <span
                        className={classes.sliderLabel}
                        style={{
                          position: "absolute",
                          display: "inline-block",
                          left: -16,
                          top: 10,
                        }}
                      >
                        0
                      </span>
                    )}
                    {props.noOFBankAccount != 10 && (
                      <span
                        className={classes.sliderLabel}
                        style={{
                          position: "absolute",
                          display: "inline-block",
                          right: 100,
                          top: 10,
                        }}
                      >
                        10
                      </span>
                    )}
                    <PrettoSlider
                      aria-labelledby="track-false-slider"
                      marks={false}
                      getAriaValueText={valuetext}
                      valueLabelDisplay="on"
                      aria-label="pretto slider"
                      step={1}
                      min={0}
                      max={10}
                      onChangeCommitted={(event, value) => {
                        setValueByDynamicName("noOFBankAccount", +value);
                        setFieldValue("noOFBankAccount", +value);
                      }}
                      value={props.noOFBankAccount}
                    />
                  </div>
                </Grid>
                <Grid item xs={11} sm={8}>
                  <Typography className={classes.question} gutterBottom>
                    Do you want us to manage sending/receiving invoices?
                  </Typography>
                </Grid>
                <Grid item xs={11} sm={8}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={11} sm={4}>
                      <Button
                        fullWidth
                        classes={{ root: classes.buttonRoot }}
                        variant="outlined"
                        color="default"
                        style={
                          props.isManageInvoice === "true"
                            ? {
                                background:
                                  localStorage.getItem("themePrimaryColor") ||
                                  "#5BA8AA",
                                color: "#fff",
                              }
                            : {
                                color: "#ccc",
                                background: "#fff",
                              }
                        }
                        name="isManageInvoice"
                        onClick={handleButtonChangeYes}
                      >
                        Yes
                      </Button>
                    </Grid>
                    <Grid item sm={4} xs={11}>
                      <Button
                        fullWidth
                        classes={{ root: classes.buttonRoot }}
                        variant="outlined"
                        color="default"
                        style={getNoButtonStyle(values, "isManageInvoice")}
                        name="isManageInvoice"
                        onClick={handleButtonChangeNo}
                      >
                        No
                      </Button>
                    </Grid>
                    <Grid xs={12} item>
                      <Typography color="error">
                        {getError(errors, touched, "isManageInvoice")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={8} xs={11}>
                  <Typography className={classes.question} gutterBottom>
                    Generally, how many invoices do you send/receive monthly?
                  </Typography>
                </Grid>

                <Grid item sm={8} xs={11}>
                  <div style={{ position: "relative", width: "100%" }}>
                    {props.noOFInvoice != 0 && (
                      <span
                        className={classes.sliderLabel}
                        style={{
                          position: "absolute",
                          display: "inline-block",
                          left: -16,
                          top: 10,
                        }}
                      >
                        0
                      </span>
                    )}
                    {props.noOFInvoice != 50 && (
                      <span
                        className={classes.sliderLabel}
                        style={{
                          position: "absolute",
                          display: "inline-block",
                          right: 100,
                          top: 10,
                        }}
                      >
                        50+
                      </span>
                    )}
                    <PrettoSlider
                      aria-labelledby="track-false-slider"
                      marks={false}
                      getAriaValueText={valuetext}
                      valueLabelDisplay="on"
                      aria-label="pretto slider"
                      min={0}
                      max={50}
                      onChangeCommitted={(event, value) => {
                        setValueByDynamicName("noOFInvoice", +value);
                        setFieldValue("noOFInvoice", +value);
                      }}
                      value={props.noOFInvoice}
                    />
                  </div>
                  <div>
                    <Typography color="error">
                      {getError(errors, touched, "noOFInvoice")}
                    </Typography>
                  </div>
                </Grid>
                {props.currentAccountingSystem !== "None" && (
                  <>
                    <Grid item xs={8}>
                      <Typography className={classes.question} gutterBottom>
                        Are your balance sheet and profit statement completely
                        accurate within the last month?
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item sm={4} xs={11}>
                          <Button
                            fullWidth
                            classes={{ root: classes.buttonRoot }}
                            variant="outlined"
                            color="default"
                            style={
                              props.isBalanceSheetAccurate
                                ? {
                                    background:
                                      localStorage.getItem(
                                        "themePrimaryColor"
                                      ) || "#5BA8AA",
                                    color: "#fff",
                                  }
                                : {
                                    color: "#ccc",
                                    background: "#fff",
                                  }
                            }
                            id='isBalanceSheetAccurate-Yes'
                            name="isBalanceSheetAccurate"
                            onClick={handleBalanceSheetChangeYes}
                          >
                            Yes
                          </Button>
                        </Grid>
                        <Grid item sm={4} xs={11}>
                          <Button
                            fullWidth
                            classes={{ root: classes.buttonRoot }}
                            variant="outlined"
                            color="default"
                            style={getNoButtonStyleNew(
                              props,
                              "isBalanceSheetAccurate"
                            )}
                            id='isBalanceSheetAccurate-no'
                            name="isBalanceSheetAccurate"
                            onClick={handleBalanceSheetChangeNo}
                          >
                            No
                          </Button>
                        </Grid>
                        <Grid xs={12} item>
                          <Typography color="error">
                            {getError(
                              errors,
                              touched,
                              "isBalanceSheetAccurate"
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container alignItems="center">
                        <Grid item xs={10}>
                          <Typography className={classes.question} gutterBottom>
                            If not, How many months are you behind on your
                            accounting?
                          </Typography>
                        </Grid>
                        <Grid item sm={2} xs={11}>
                          <FormControl className={classes.formControl}>
                            <TextField
                              select
                              color="primary"
                              disabled={!!props.isBalanceSheetAccurate}
                              value={props.noOfmonthForBalanceSheet}
                              id='noOfmonthForBalanceSheet'
                              name="noOfmonthForBalanceSheet"
                              onChange={handless}
                              onBlur={handleBlur}
                              helperText={getError(
                                errors,
                                touched,
                                "noOfmonthForBalanceSheet" 
                              )}
                              error={
                                !!getError(
                                  errors,
                                  touched,
                                  "noOfmonthForBalanceSheet"
                                )
                              }
                              variant="outlined"
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value="">
                                <em>Select</em>
                              </MenuItem>
                              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, "12"].map(
                                (status) => {
                                  return (
                                    <MenuItem key={status} value={status}>
                                      {status}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </TextField>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} className={classes.buttonContainer}>
                  <Grid container justifyContent="flex-end">
                    <Grid item sm={3} xs={12}>
                      <Button
                        color="default"
                        style={{ marginRight: "16px" }}
                        startIcon={<ArrowBackOutlinedIcon />}
                        variant="text"
                        id="backButton"
                        className={classes.backButton}
                        onClick={goBack}
                      >
                        Back 
                      </Button>
                      <Button
                        endIcon={<ArrowForwardOutlinedIcon />}
                        variant="text"
                        disabled={!isValid}
                        type="submit"
                        color="primary"
                        id="nextButton"
                        className={[
                          classes.nextButton,
                          "statucNextButton",
                        ].join(" ")}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Fade>
  );
};
export default withStyles((theme) =>
  createStyles({
    ...surveyStyles(theme),
    ...aboutUsStyle(theme),
  })
)(AccountingComponent);
