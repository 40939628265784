//@ts-nocheck
import React from "react";
import {
  withStyles, Grid, createStyles, Button, Box, Modal, Paper, Typography, Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import DocViewer from "@cyntler/react-doc-viewer";
import SignaturePad from "react-signature-canvas";
import GetAppIcon from "@material-ui/icons/GetApp";
import mammoth from "mammoth";
import "react-quill/dist/quill.snow.css";
import { Editor } from '@tinymce/tinymce-react';


const MyCustomPNGRenderer: any = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;
  return (
    <div style={{ width: "100%", height: 500 }} id="my-png-renderer">
      <iframe
        style={{ width: "100%", height: 500 }}
        src={`https://docs.google.com/viewer?url=${currentDocument.uri}&embedded=true`}
      />
    </div>
  );
};
MyCustomPNGRenderer.fileTypes = ["doc", "docx"];
MyCustomPNGRenderer.weight = 1;

export class ViewDocumentDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      openSignaturePad: false,
      EditMode: false,
      signatureData: null,
      imageURL: null,
      content: "",
      wordFile: null,
      isPreviewOpen: false,
      info: props.dataToPass?.info || false,
      isDataAvailable: props.dataToPass?.isDataAvailable || false
    };
    this.sigCanvas = React.createRef();
  }
  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState({ token });
    }
    if (!this.state.info && this.props.dataToPass.documentItem) {
      this.loadWordFileFromURL()
    }
  }

  loadWordFileFromURL = async () => {
    this.props.showLoader();
    const { dataToPass } = this.props;
    const data = dataToPass.btnshow ? dataToPass.documentItem : dataToPass.documentItem.attributes;
    if (data.description != null) {
      this.setState({
        content: data.description
      })
    }
    else {
      const wordFileURL = dataToPass.btnshow ? dataToPass?.documentItem.document : dataToPass?.documentItem.attributes.document;
      if (wordFileURL) {
        try {
          const response = await fetch(wordFileURL);
          const arrayBuffer = await response.arrayBuffer();
          const { value } = await mammoth.extractRawText({ arrayBuffer });

          this.setState({ content: value });
        } catch (error) {
          console.error("Error loading Word file:", error);
        }
      }
    }
    this.props.hideLoader();
  };

  handleSubmit2 = () => {
    this.setState({ openSignaturePad: true });
  };

  saveSignature = () => {
    if (!this.sigCanvas.current.isEmpty()) {
      const signatureData = this.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      if (this.props.dataToPass.onCheckboxSubmit) {
        this.props.dataToPass.onCheckboxSubmit(
          true,
          signatureData,
          this.props.dataToPass.survey_id,
          this.props.dataToPass.document_template_id,
        );
      }
      this.setState({ openSignaturePad: false });
      this.props.onCancel();
    }
    else {
      this.setState({ openSignaturePad: false });
      if (this.props.dataToPass.onCheckboxSubmit) {
        this.props.dataToPass.onCheckboxSubmit(
          false,
          this.props.dataToPass.survey_id,
          this.props.dataToPass.document_template_id,
        );
      }
    }
  };

  clearSignature = () => {
    this.sigCanvas.current.clear();
  };
  open = () => {
    window.open(
      this.props.dataToPass?.documentItem?.attributes?.document,
      "_blank",
      "noopener"
    );
  };

  handleContentChange = (value) => {
    this.setState({ content: value });
  };

  closePreview = () => {
    this.setState({ isPreviewOpen: false });
    this.props.onCancel();
    this.props.onSubmit(this.state.content);
  };

  ContentRender = (dataToPass) => {
    let contentToRender
    const check = dataToPass.btnshow ? dataToPass?.documentItem : dataToPass?.documentItem.attributes

    if (dataToPass.view) {
      contentToRender = null;
    } else if (dataToPass.btnshow) {
      contentToRender = (
        <>
          <Box display="flex" justifyContent="center" alignItems="center" width="100%" sx={{
            gap: "5%"
          }}>
            {check.description == null ? (<Button
              component="a"
              href={check.document}
              target="_blank"
              rel="noopener noreferrer"
              download
              color="primary"
              style={{
                width: "25%",
                marginTop: "1.5rem",
                textDecoration: "none",

              }}
              startIcon={<GetAppIcon />}
            >
              Download PDF
            </Button>) : ("")}
            <Button
              variant="contained"
              color="secondary"
              style={{
                width: "25%",
                marginTop: "1.5rem",
              }}
              onClick={this.props.onCancel}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                width: "25%",
                marginTop: "1.5rem",
              }}
              onClick={this.handleSubmit2}
            >
              Accept
            </Button>
          </Box>
        </>
      );
    } else if (dataToPass.EditMode) {
      contentToRender = (<>
        <Box display="flex" justifyContent="space-around" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            style={{
              marginTop: "1.5rem",
              textTransform: "none"
            }}
            onClick={() => { this.setState({ isPreviewOpen: true }) }}
          >
            Preview and Save
          </Button>
        </Box>
      </>
      );
    } else if (check?.description != null) {
      contentToRender = (<Box display="flex" justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          onClick={
            this.props.onCancel
          }
          style={{
            width: "25%",
            marginTop: "1.5rem",
          }}
        >
          Close
        </Button>
      </Box>)
    } else {
      contentToRender = (<>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            onClick={this.open.bind(this)}
            style={{
              width: "25%",
              marginTop: "1.5rem",
            }}
          >
            Download
          </Button>
        </Box>
      </>
      );
    }
    return contentToRender
  }

  ClientDetails = (dataToPass, classes) => {
    if (!dataToPass.btnshow || dataToPass.signmode) return null;
    return (
      <Box
        sx={{
          marginTop: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Typography>
              <span className={classes.tableCellLabel}>Date & Time:</span>
              <span className={classes.tableCellValue}>
                {new Date(dataToPass.SignDetails?.signing_time).toLocaleString()}
              </span>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              <span className={classes.tableCellLabel}>Browser:</span>
              <span className={classes.tableCellValue}>{dataToPass.SignDetails?.browser_details}</span>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              <span className={classes.tableCellLabel}>IP Address:</span>
              <span className={classes.tableCellValue}>
               {dataToPass.SignDetails?.ip_address}
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ margin: "10px 0" }}>
            Client's Signature:
          </Typography>
          <div className={classes.viewsignatureBox}>
            <img
              src={dataToPass.SignDetails?.signature}
              alt="Signature"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </Grid>
      </Box>
    );
  };


  render() {
    const { classes, dataToPass } = this.props;
    let contentToRender, documentToRender;
    let docs = [];
    if (!this.state.info && dataToPass?.documentItem) {
      const data = dataToPass.btnshow ? dataToPass.documentItem : dataToPass.documentItem.attributes
      docs = [
        {
          uri: dataToPass.btnshow ? dataToPass.documentItem.document : dataToPass.documentItem.attributes?.document,
          fileType: "doc",
        },
      ];
      contentToRender = this.ContentRender(dataToPass)
      if (dataToPass.EditMode) {
        documentToRender = (<>
          <Grid item xs={12}>
            <Editor
              apiKey="hbphmouh4uafs0kkbdtiugtex5vy7bmyaumir5e2t29uhsqm"
              value={this.state.content}
              init={{
                selector: '#basic-conf',
                plugins: [
                  'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                  'searchreplace', 'wordcount', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media',
                  'table', 'emoticons', 'help'
                ],
                toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | ' +
                  'bullist numlist outdent indent | link image | print preview media fullscreen | ' +
                  'forecolor backcolor emoticons | help',
                menu: {
                  favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
                },
                menubar: 'favs file edit view insert format tools table help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
              }}
              onEditorChange={this.handleContentChange}
            />
          </Grid>
        </>)
      } else if (data?.description != null) {
        documentToRender = (<>
          <Grid item xs={12}>
            <Box sx={{
              border: "1px solid #ccc",
              padding: "20px",
            }}>
              <Typography
                variant="h6"
                style={{
                  textAlign: "center", fontSize: "20px",
                  fontWeight: "900"
                }}
              >{this.props.dataToPass?.documentItem?.attributes?.title || data.document_template_title || data.title}</Typography>
              <Editor
                initialValue={this.state.content}
                apiKey="hbphmouh4uafs0kkbdtiugtex5vy7bmyaumir5e2t29uhsqm"
                init={{
                  statusbar: false,
                  menubar: false,
                  draggable: false,
                  toolbar: false,
                  paste_as_text: true,
                  spellchecker: false,
                  contextmenu: false,
                  paste: false,
                  readoxnly: true,

                  content_style: `
                  table td { 
                    pointer-events: none;
                  }
                  body { 
                    font-size: 16px; 
                    cursor: default; 
                    user-select: none;
                    font-family: Helvetica, Arial, sans-serif; 
                  }
                `,
                  setup: (editor) => {
                    editor.on('focus', function () {
                      editor.getBody().blur();
                    });
                    editor.on('mousedown keydown keypress', function (e) {
                      e.preventDefault();
                    });
                  },
                }}
              />
              {this.ClientDetails(dataToPass, classes)}
            </Box>
          </Grid>
        </>)
      } else {
        documentToRender = (<>
          <Grid item xs={12}>
            <DocViewer
              documents={docs}
              prefetchMethod="GET"
              config={{
                header: { disableHeader: true },
              }}
              pluginRenderers={[MyCustomPNGRenderer]}
              style={{ width: "100%", height: 500 }}
            />
            {this.ClientDetails(dataToPass, classes)}
          </Grid>
        </>)
      }
    }
    if (this.state.info) {
      return (
        <>
          <Typography variant="h3" className={classes.title}>
            Client Information
          </Typography>
          {this.state.isDataAvailable ? (
            <Paper className={classes.tableContainer}>
              <Box sx={{
                marginBottom: "10px"
              }}>
                <Grid item xs={12}>
                  <Typography>
                    <span className={classes.tableCellLabel}>Email:</span>
                    <span className={classes.tableCellValue}>{this.props.dataToPass.clientInfo.client_email}</span>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography>
                    <span className={classes.tableCellLabel}>Full name:</span>
                    <span className={classes.tableCellValue}>{this.props.dataToPass?.clientInfo.client_full_name}</span>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography>
                    <span className={classes.tableCellLabel}>Date:</span>
                    <span className={classes.tableCellValue}> {new Date(this.props.dataToPass.clientInfo.signing_time).toLocaleString()}</span>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography>
                    <span className={classes.tableCellLabel}>Browser:</span>
                    <span className={classes.tableCellValue}>
                      {this.props.dataToPass.clientInfo.browser_details} <br />
                      IP: {this.props.dataToPass.clientInfo.ip_address}
                    </span>
                  </Typography>
                </Grid>
              </Box>
              <Box display="flex" justifyContent="center">

                <Grid item xs={12}>
                  <Typography className={classes.tableCellLabel}> Client Signature : </Typography>
                  <div className={classes.signatureBox}>
                    <img src={this.props.dataToPass.clientInfo.signature}
                      alt="Client Signature" style={{ width: "100%", height: "100%" }} />
                  </div>
                </Grid>
              </Box>
              <Grid item xs={12} justifyContent="center" style={
                {
                  display: "flex",
                  marginTop: "30px"
                }}>
                <Button variant="contained"
                  color="primary"
                  onClick={this.props.onCancel}>
                  Close
                </Button>
              </Grid>
            </Paper>
          ) : (
            <Grid container justifyContent="center" className={classes.rootContainer}>
              <Typography variant="h6" color="textSecondary" align="center">
                Document signature is pending
              </Typography>
              <Grid item xs={12} justifyContent="center" style={
                {
                  display: "flex",
                  marginTop: "30px"
                }}>
                <Button variant="contained"
                  color="primary"
                  onClick={this.props.onCancel}>
                  Close
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      );
    }

    return (
      <Grid container justifyContent="center" className={classes.rootContainer}>
        {documentToRender}
        {contentToRender}
        <Dialog
          open={this.state.isPreviewOpen}
          onClose={this.closePreview}
          style={{ width: "70vw", margin: "auto", height: "110vh", borderRadius: "16px" }}
          PaperProps={{
            style: {
              width: "70vw",
              height: "110vh",
              borderRadius: "16px",
            },
          }}
          fullWidth
          maxWidth={false}
        >
          <DialogTitle>
            <Typography
              variant="h6"
              style={{
                textAlign: "center", fontSize: "20px",
                fontWeight: "900"
              }}
            >
              {this.props.dataToPass?.documentItem?.attributes?.title}
            </Typography>
          </DialogTitle>
          <DialogContent dividers style={{ maxHeight: "80vh", overflowY: "auto", padding: "0 1rem" }}>
            <Editor
              apiKey="hbphmouh4uafs0kkbdtiugtex5vy7bmyaumir5e2t29uhsqm"
              initialValue={this.state.content}
              init={{
                toolbar: false,
                menubar: false,
                statusbar: false,
                readonly: true,
                draggable: false,
                paste_as_text: true,
                contextmenu: false,
                paste: false,
                spellchecker: false,
                content_style: `
                  body { 
                    font-family: Helvetica, Arial, sans-serif; 
                    font-size: 16px; 
                    user-select: none;
                    cursor: default; 
                  }
                  table td { 
                    pointer-events: none;
                  }
                `,
                setup: (editor) => {
                  editor.on('mousedown keydown keypress', function (e) {
                    e.preventDefault();
                  });
                  editor.on('focus', function () {
                    editor.getBody().blur();
                  });
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Box sx={
              {
                width: "100%", display: "flex", justifyContent: "center"
              }
            }>
              <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "1rem"
                }}
                onClick={() => {
                  this.setState({ isPreviewOpen: false })
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  margin: "1rem"
                }}
                onClick={this.closePreview}
              >
                Save
              </Button>

            </Box>
          </DialogActions>
        </Dialog>
        <Modal
          open={this.state.openSignaturePad}
          onClose={() => this.setState({ openSignaturePad: false })}
          aria-labelledby="signature-modal"
          aria-describedby="signature-pad"
        >
          <div className={classes.modalContainer}>
            <h2 id="signature-modal">Sign Document</h2>
            <SignaturePad
              ref={this.sigCanvas}
              canvasProps={{ className: "signatureCanvas" }}
              backgroundColor="#f4f4f4"
              style={{ border: "1px solid #000" }}
            />
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={this.saveSignature}
                style={{ margin: "10px", textTransform: "none" }}
              >
                Accept and Sign
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={this.clearSignature}
                style={{ margin: "10px" }}
              >
                Clear
              </Button>
            </Box>
          </div>
        </Modal>
      </Grid >
    );
  }
}

const ViewDocumentDialogWithRouter = withRouter(ViewDocumentDialog);
const ViewDocumentDialogWithToast = withToast(ViewDocumentDialogWithRouter);
const ViewDocumentDialogWithLoader = withLoader(ViewDocumentDialogWithToast);
const ViewDocumentDialogWithAlertBox = withAlertBox(
  ViewDocumentDialogWithLoader
);
const ViewDocumentDialogWithDialogBox = withDialogBox(
  ViewDocumentDialogWithAlertBox
);


export default withStyles((theme) =>
  createStyles({
    rootContainer: {
      width: "60vw",
      paddingBottom: "2rem",
      marginTop: "2.5rem",
    },
    modalContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fff",
      padding: "20px",
      outline: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    signatureCanvas: {
      width: "700px",
      height: "400px",
      border: "1px solid #000",
    },


    tableContainer: {
      padding: theme.spacing(3),
      backgroundColor: "#f9f9f9",
      borderRadius: "10px",
      marginBottom: theme.spacing(5),

    },

    table: {
      width: "100%",
      borderCollapse: "collapse",
      fontSize: "17px"
    },
    tableRow: {
      borderBottom: "1px solid #e0e0e0",
    },
    tableCellLabel: {
      fontWeight: "bold",
      textAlign: "left",
      fontSize: "14px"
    },
    tableCellValue: {
      padding: theme.spacing(1),
      textAlign: "right",
      wordBreak: "break-word",
      fontSize: "14px"
    },
    signatureContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(2),
    },
    signatureBox: {
      border: "1px solid #ccc",
      padding: theme.spacing(1),
      width: "200px",
      height: "200px",
      overflow: "hidden",
    },
    viewsignatureBox: {
      width: "150px",
      padding: theme.spacing(1),
      overflow: "hidden",
      height: "150px",
      border: "1px solid #ccc",
    },
    title: {
      marginBottom: "1rem",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "20px"
    },

  })
)(ViewDocumentDialogWithDialogBox);
